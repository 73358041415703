import { useState } from 'react'
import { createUseStyles } from "react-jss"

import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { VariableType } from "@easy-templates/types"
import Select from "components/Select"
import Textfield from "components/ui/Textfield"
import Checkbox from "components/ui/Checkbox"
import SectionMessage from "components/ui/SectionMessage"
import { variableFieldFactory, useVariableOptions } from "components/Variables"
import { Field, ErrorMessage, useFormState, Fieldset } from "components/ui/form"
import { IconButton } from 'components/ui/Button'
import { DeleteIcon, GripIcon } from 'components/ui/icons'
import Button from 'components/ui/Button'

type Value = string | number | readonly string[] | { value: string } | undefined

type Values = {
  type?: { id: VariableType }
  label?: string
  description?: string
  default?: Value
  required?: boolean,
  config?: {
    options?: string[]
  }
}

type Props = {
  takenLabels: string[]
  isSubmitting?: boolean
  isTypeDisabled?: boolean
  initialValues?: Values
  setFieldValue: (field: string, value: Value) => void
}

const useStyles = createUseStyles({
  option: {
    display: "flex", justifyContent: "flex-start", alignItems: "center", width: '65%'
  },
  optionInput: {
    flexGrow: 1,
  }
})

const testIdFor = (name: string) => `variable-form__${name}`

const labelTransformer = (eventOfValue: React.FormEvent<HTMLInputElement> | string) => {
  let label = ""

  if (typeof eventOfValue === 'object' && eventOfValue.currentTarget) {
    label = eventOfValue.currentTarget.value as string
  } else {
    label = eventOfValue as string
  }

  return label.trimStart()
}

export const labelValidator = ({ initialValue, takenLabels }: { takenLabels: string[], initialValue: string }) => async (value: string) => {
  const validLabelCharsRegex = /[\w\-\s\?]+$/
  const invalidLabel = /\s$/

  if (!value) {
    return "Label cannot be empty"
  }

  if (!value.match(validLabelCharsRegex)) {
    return "Label must contain only letters, numbers, hyphens, underscores, spaces and questionmark"
  }

  if (value.match(invalidLabel)) {
    return "Label must not end with a space"
  }

  if (value.length > 50) {
    return "Label must be 50 characters or less"
  }

  const normLabel = value.trim().toLowerCase()
  const normInitialLabel = initialValue?.trim().toLowerCase()

  const isLabelChanged = normLabel !== normInitialLabel

  if (isLabelChanged && takenLabels.includes(normLabel)) {
    return "Label must be unique in the template"
  }
}

export const validateDescription = (value: string = '') => {
  if (value.length > 255) {
    return "Description must be 255 characters or less"
  }
}

const DefaultValueField = ({ defaultValue }: { defaultValue?: Value }) => {
  const formState = useFormState({ invalid: true, submitting: true, values: true, initialValues: true })
  const [isDirty, setIsDirty] = useState(false)

  if (!formState) { return null }

  const {
    component: VariableField,
    validation: variableValidation,
    transformer: variableTransformer,
    props: variableFieldProps
  } = variableFieldFactory("default", formState.values.type.id, false, formState.values.config)

  const value = formState.values.default === undefined && !isDirty ? defaultValue : formState.values.default

  return (
    <Field<Value>
      name="default"
      label="Default value"
      isDisabled={formState.submitting}
      defaultValue={variableTransformer.transform(value)}
      testId={testIdFor("default")}
      validate={variableValidation.validate}
      transform={variableTransformer.transform}
    >
      {({ fieldProps, error }) => (
        <>
          {/* @ts-ignore: Doesn't like onBlur prop */}
          <VariableField
            {...fieldProps}
            {...variableFieldProps}
            value={variableTransformer.transform(fieldProps.value)}
            onChange={value => {
              setIsDirty(true)
              fieldProps.onChange(value)
            }}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  )
}

const FieldOptions = ({ setFieldValue, defaultOptions }) => {
  const formState = useFormState({
    invalid: true, submitting: true, values: true, initialValues: true, errors: true
  })

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  )

  const classes = useStyles()

  if (!formState || formState.values.type.id !== 'select') { return null }

  const options = formState.values.config?.options || defaultOptions || []

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const newOptions = arrayMove(options, active.id, over.id)
      setFieldValue('config.options', newOptions)
    }
  }

  return (
    <Fieldset legend="Options">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={options.map((option, index) => ({ id: index, option }))}
          strategy={verticalListSortingStrategy}
        >

          {options.map((value, index) => (
            <Field
              name={`config.options[${index}]`}
              transform={event => {
                let value = ''

                if (typeof event == 'string') {
                  value = event
                } else if ('currentTarget' in event) {
                  value = event.currentTarget.value
                }

                return value.replace(/[^\w\d\?\-\s]+/, '')
              }}
            >
              {({ fieldProps, error }) => {
                const {
                  attributes,
                  listeners,
                  setNodeRef,
                  transform,
                } = useSortable({ id: index })

                const style = {
                  transform: CSS.Transform.toString(transform),
                }

                return (
                  <div className={classes.option} style={{ ...style }} ref={setNodeRef} {...attributes} {...listeners}>
                    <div>
                      <GripIcon label='Grip' size='medium' />
                    </div>
                    <div className={classes.optionInput}>
                      <Textfield
                        {...fieldProps}
                        onChange={event => {
                          fieldProps.onChange(event)

                          setFieldValue('default', null)
                        }}
                        defaultValue={value}
                        testId='option-input'
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </div>
                    <IconButton
                      icon={DeleteIcon}
                      label="Delete option"
                      appearance='subtle'
                      shape='circle'
                      onClick={() => {
                        const newOptions = options.filter((_, i) => i !== index)
                        setFieldValue('config.options', newOptions)
                        if (!newOptions.includes(formState.values.default?.value)) {
                          setFieldValue('default', null)
                        }
                      }}
                      testId='delete-option'
                    />
                  </div>
                )
              }}
            </Field>
          ))}

        </SortableContext>
      </DndContext>
      <Field name="config.options" defaultValue={defaultOptions} validate={(data) => {
        if (!data || data.length === 0) {
          return "Plese add some options"
        }
      }
      }>
        {({ error, valid }) => (
          <>
            {!valid && <div style={{ marginBottom: 8 }}><ErrorMessage>{error || formState.errors.config?.options}</ErrorMessage></div>}
            <Button
              testId='add-option'
              onClick={() => { setFieldValue('config.options', [...options, ""]) }}
            >
              Add option
            </Button>
          </>)}
      </Field>
    </Fieldset>
  )
}

const FormPreview = () => {
  const formState = useFormState({
    values: true,
    pristine: true,
    dirty: true,
    valid: true,
    errors: true,
  })

  return (
    <pre>{JSON.stringify(formState, null, 2)}</pre>
  )
}

const Form = ({
  initialValues,
  isSubmitting,
  isTypeDisabled,
  takenLabels,
  setFieldValue,
}: Props) => {
  const { options: variableOptions } = useVariableOptions()

  const typeOptions = variableOptions.map(variable => ({
    id: variable.type,
    name: variable.label,
    iconComponent: variable.Icon,
    isDisabled: variable.comingSoon
  }))

  const selectedType = initialValues?.type ? typeOptions.find(type => type.id === initialValues.type.id) : typeOptions[0]

  return (
    <div data-testid="variable-form">
      <Field name="error" testId={testIdFor("error")}>
        {({ error }) => (
          <>
            {error &&
              <SectionMessage appearance="error">
                {error}
              </SectionMessage>}
          </>
        )}
      </Field>
      <Field label="Type" name="type" testId={testIdFor("type")} defaultValue={selectedType}>
        {({ fieldProps }) => (
          <Select
            {...fieldProps}
            isClearable={false}
            isDisabled={isTypeDisabled || fieldProps.isDisabled}
            isMulti={false}
            isSearchable={false}
            options={typeOptions}
            menuPosition="fixed"
          />
        )}
      </Field>
      <Field name="label"
        label="Label"
        isRequired={true}
        isDisabled={isSubmitting}
        defaultValue={initialValues?.label}
        validate={labelValidator({ takenLabels, initialValue: initialValues?.label })}
        transform={labelTransformer}
        testId={testIdFor("label")}
      >
        {({ fieldProps, error }) => (
          <>
            <Textfield
              {...fieldProps}
              autoFocus
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
      <Field name="description"
        label="Description"
        isDisabled={isSubmitting}
        defaultValue={initialValues?.description}
        validate={validateDescription}
        testId={testIdFor("description")}>
        {({ fieldProps, error }) => (
          <>
            <Textfield
              {...fieldProps}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
      <FieldOptions setFieldValue={setFieldValue} defaultOptions={initialValues?.config?.options} />
      <DefaultValueField defaultValue={initialValues?.default} />
      <Field
        name="required"
        testId={testIdFor("required")}
        isDisabled={isSubmitting}
      >
        {({ fieldProps }) => (
          <Checkbox
            {...fieldProps}
            label={"Require non-empty value"}
            defaultChecked={initialValues?.required}
          />
        )}
      </Field>
      {/* <FormPreview /> */}
    </div>
  )
}

export default Form
