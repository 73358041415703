import { ChangeEvent, useReducer, useEffect } from "react"

import { Job, TemplateScope, Template } from "@easy-templates/types"

import { useAppContext } from "components/AppContextProvider"

import Maintenance from "components/maintenance"
import TemplatesEmptyState from "components/Templates/Empty"
import ErrorState from "components/ErrorState"
import LockedState from "components/LockedState"
import { DialogLayout } from "components/Layout"
import Button, { ButtonGroup } from "components/ui/Button"
import Checkbox from "components/ui/Checkbox"
import TextField from "components/ui/Textfield"
import CreationProgress from "components/ui/progress"
import Form from "components/ui/form"

import Variables, { normalizeVariableValuesKeys } from "components/Variables"

import { queryFilterFor } from "lib/query-filter"

import useTemplate from "hooks/useTemplate"
import useJob from "hooks/useJob"

import useFormData from "./useFormData"
import useApplyTemplate from "./useApplyTemplate"
import useStyles from "./useStyles"

import TemplatesList from "./List"
import reducer, {
  State,
  MatchIssueTypeChanged,
  QueryChangedAction,
  ApplyingFailed,
  ApplyingStarted,
  ApplyingFinished,
  TemplateIsSelected,
  TemplateLoaded,
  JobUpdatedAction,
  ApplyingRequested,
  TemplateIsUnselected
} from "./reducer"

type VariableValues = { [name: string]: any }

const initialState: State = {
  isLoadingPermissions: false,
  mustMatchIssueType: true,
  isProcessing: false,
  selectedTemplateId: undefined,
  showVariablesForm: false,
  variableValues: {},
  variables: [],
  results: undefined,
  showResults: false
}

const ApplyPage = (): JSX.Element => {
  const { core, maintenanceModeOn, ...context } = useAppContext()

  const classes = useStyles()

  const [
    state,
    dispatch,
  ] = useReducer(reducer, initialState)

  const {
    jobId,
    mustMatchIssueType,
    query,
    results,
    isProcessing,
    selectedTemplate,
    selectedTemplateId,
    showVariablesForm,
    showResults,
    variables,
  } = state

  const {
    templates,
    hasPermissions,
    isLoading: isLoadingTemplates,
    error: loadingFormDataError,
  } = useFormData({ projectId: context.project?.id })

  const navigateToIssue = (issueData) => {
    core.navigateToIssue(issueData)
    closeDialog()
  }

  const handleSuccess = (results) => {
    core.refreshIssuePage()

    dispatch(new ApplyingFinished(results, navigateToIssue))

    core.showFlag({
      id: "template-applied",
      title: "Success!",
      description: "The Issue has been successfully updated.",
      type: "success",
    })

    if (Object.keys(results).length === 1) {
      closeDialog()
    }
  }

  const handleFailure = (data) => {
    let results

    if ("results" in data) {
      results = data.results
    } else {
      results = data
    }

    const rootResult = results[selectedTemplate.rootIssueId]

    core.showFlag({
      id: "template-applying-failed",
      title: "Unable to Apply Template",
      description: rootResult.result,
      type: "error",
    })

    dispatch(new ApplyingFailed(rootResult.result))

    if (Object.keys(results).length === 1) {
      closeDialog()
    }
  }

  useJob({
    id: jobId,
    onChange: (job: Job) => {
      dispatch(new JobUpdatedAction(job))

      if (job.status === "error") {
        handleFailure(job)
      } else if (job.status === "success") {
        handleSuccess(job.results)
      }
    },
    onError: (error: Error) => {
      dispatch(
        new ApplyingFailed(
          error.message || "Error updating creation status"
        )
      )
    },
  })

  const {
    isInitialLoading: isLoadingTemplate,
    error: loadingTemplateError
  } = useTemplate({
    id: selectedTemplateId,
    onCompleted: (template) => {
      dispatch(new TemplateLoaded(template))
    },
  })

  useEffect(() => {
    if (selectedTemplate?.variables.length == 0) {
      handleApply({})
    }
  }, [selectedTemplate?.id])

  const error = loadingTemplateError ||
    loadingFormDataError

  const isLoading = isLoadingTemplates ||
    isLoadingTemplate ||
    (isProcessing && !showResults)

  const handleBackToListClick = () => dispatch(new TemplateIsUnselected())
  const handleMatchIssueTypeChanged = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(new MatchIssueTypeChanged(e.target.checked))
  }

  const { applyTemplate } = useApplyTemplate()

  const handleQueryChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(new QueryChangedAction(event.target.value))
  }

  const handleSelectTemplate = async (templateId: string) => {
    const template = templates.find(({ id }) => id === templateId)

    dispatch(new TemplateIsSelected(template))
  }

  const closeDialog = () => {
    core.closeApplyTemplateDialog()
  }

  const handleApply = async ({ variableValues: formVariableValues }: { variableValues?: VariableValues }) => {
    const variableValues = normalizeVariableValuesKeys(formVariableValues)

    dispatch(new ApplyingRequested(selectedTemplate, variableValues))

    try {
      const data = await applyTemplate(
        {
          issueKey: context.issue?.key,
          projectId: context.project?.id,
          templateId: selectedTemplate.id,
          variableValues,
        })
      if ("jobId" in data) {
        return dispatch(new ApplyingStarted(data.jobId as string))
      }

      const rootResult = data[selectedTemplate.rootIssueId]

      if (rootResult.status == "ok") {
        handleSuccess(data)
      } else {
        handleFailure(data)
      }
    } catch (error) {
      core.showFlag({
        id: "template-applying-failed",
        title: "Unable to Apply Template",
        description: error.message,
        type: "error",
      })

      dispatch(new ApplyingFailed(error.message))
    }
  }

  const queryFilter = queryFilterFor<Template>(
    (template) => `${template.name} ${template.issuetype.name}`
  )

  const findTemplates = (templates: Template[] = []): Template[] => {
    const templatesByName = queryFilter.filter(templates, query)

    if (!context.project || !context.issue?.issuetype) {
      return templatesByName
    }

    const templatesByScope = templatesByName.filter((template) => {
      if (template.scope === TemplateScope.GLOBAL) {
        return template
      }

      return template.scopeValue.includes(context.project?.id)
    })

    if (mustMatchIssueType) {
      return templatesByScope.filter((template) => {
        return context.issue.issuetype.id === template.issuetype.id
      })
    }

    return templatesByScope
  }

  if (maintenanceModeOn) {
    return <Maintenance onDismiss={closeDialog} />
  }

  return (
    <Form onSubmit={handleApply} isDisabled={!selectedTemplate}>
      {({ formProps }) => {
        return (
          <form {...formProps}>
            <DialogLayout
              isLoading={isLoading}
              header={<h3>Apply Template</h3>}
              actions={
                <ButtonGroup>
                  <Button
                    appearance="subtle"
                    onClick={closeDialog}
                    testId="apply-template_cancel-button"
                  >
                    Close
                  </Button>
                  {showVariablesForm && (
                    <Button
                      appearance="subtle"
                      onClick={handleBackToListClick}
                      isDisabled={isLoading}
                      testId="apply-template_back-button"
                    >
                      Back
                    </Button>
                  )}
                  {showVariablesForm && (
                    <Button
                      appearance="primary"
                      type="submit"
                      autoFocus
                      isDisabled={isLoading}
                      testId="apply-template_submit-button"
                    >
                      Apply template
                    </Button>
                  )}
                </ButtonGroup>
              }
            >
              {error && <ErrorState error={error as Error} />}
              {!error && !hasPermissions && (
                <LockedState
                  header="This action is restricted"
                  message="To perform this action, you must be authorized to create issues in the project."
                />
              )}
              {!error &&
                hasPermissions &&
                !showVariablesForm &&
                !isLoading &&
                templates.length < 1 && <TemplatesEmptyState />}
              {!showResults &&
                !error && templates.length > 0 && hasPermissions && !showVariablesForm && (
                  <>
                    <div className={classes.filterBar}>
                      <TextField
                        isCompact
                        onChange={handleQueryChanged}
                        placeholder="Find by name or issue type"
                        aria-label="Find by name or issue type"
                        width={200}
                        testId="query-field"
                      />
                      <Checkbox
                        isChecked={mustMatchIssueType}
                        onChange={handleMatchIssueTypeChanged}
                        label="Only matching the Issue Type"
                        name="match-issue-type"
                        testId="match-issue-type"
                      />
                    </div>
                    <TemplatesList
                      templates={findTemplates(templates)}
                      onApply={handleSelectTemplate}
                    />
                  </>
                )}
              {!error && hasPermissions && showVariablesForm && (
                <Variables variables={variables} />
              )}
              {showResults && (
                <CreationProgress items={results} />
              )}
            </DialogLayout>
          </form>
        )
      }}
    </Form>
  )
}


export default ApplyPage
