// import "./wdyr";

import ReactDOM from "react-dom/client"
import { HashRouter as Router } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

// Internationalization
import { IntlProvider } from 'react-intl-next'

import { AppContextProvider } from "@easy-templates/ui"
import Core, { Telemetry } from "@easy-templates/lib"

import App from "./App"
import JiraClient from "adapter/jira"
import Initializer from "adapter/initializer"

import TemplatesAdapter from "adapter/templates"

import "@atlaskit/css-reset"
import "App.css"

const telemetry = new Telemetry()
const jiraClient = new JiraClient()

const templatesAdapter = new TemplatesAdapter()

const initializer = new Initializer()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

const core = new Core(jiraClient, templatesAdapter, telemetry)

window.addEventListener("load", () => {
  const wrapper = document.getElementById("root")

  if (wrapper) {
    const root = ReactDOM.createRoot(wrapper)
    root.render(
      <QueryClientProvider client={queryClient}>
        <Router>
          <AppContextProvider
            core={core}
            initializer={initializer}
            featureFlags={{
              richTextEditor: true,
              prefill: true,
              variableDate: true,
              variableNumber: true,
              variableSelect: true,
              selectIssuesToCreate: true,
            }}
          >
            <IntlProvider locale="en">
              <App />
            </IntlProvider>
          </AppContextProvider>
        </Router>
        <ReactQueryDevtools />
      </QueryClientProvider>
    )
  }
})
