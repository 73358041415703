import { useReducer } from "react"
import { useParams } from "react-router-dom"

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { ErrorDescription } from "@easy-templates/lib"

import { useAppContext } from "components/AppContextProvider"
import { LogOnMount, UIEvent } from "components/Analytics"
import Loading from "components/Loading"
import ErrorState from "components/ErrorState"
import Link from "components/Link"
import Tabs, { Tab, TabList, TabPanel } from "components/ui/tabs"
import { PageHeader } from "components/ui/Page"
import { ButtonGroup } from "components/ui/Button"
import Form from "components/ui/form"

import useStyles from './useStyles'

import Empty from "./Empty"
import Preview from "./Preview"
import List from "./List"
import AddButton from "./AddButton"
import Dialog from "./Dialog"
import VariableForm from "./Form"

import useCreateVariable from "./useCreateVariable"
import useUpdateVariable from "./useUpdateVariable"
import useDeleteVariable from "./useDeleteVariable"
import useListVariables from "./useListVariables"
import useRankVariable from "./useRankVariable"
import forEach from "lodash/forEach"
import { VariableType } from "@easy-templates/types"

type Values = {
  label: string,
  description: string,
  required: boolean,
  type: { id: VariableType },
  default: string | number | { value: string } | undefined
  config?: {
    options?: string[]
  }
}

abstract class Action {
  apply(state: State): State {
    return state
  }
}

class NewVariableModalRequested extends Action {
  constructor(public type: VariableType) {
    super()
  }

  apply(state: State): State {
    return {
      ...state,
      initialValues: {
        ...state.initialValues,
        type: { id: this.type },
      },
      isNewVariableModalOpen: true,
    }
  }
}

class EditVariableModalRequested extends Action {
  constructor(public id: string) {
    super()
  }

  apply(state: State): State {
    return {
      ...state,
      isEditVariableModalOpen: true,
      currentVariableId: this.id,
    }
  }
}

class CloseDialogRequested extends Action {
  apply(state: State): State {
    return {
      ...state,
      isNewVariableModalOpen: false,
      isEditVariableModalOpen: false,
      currentVariableId: null,
    }
  }
}

type State = {
  currentVariableId: string | null
  isEditVariableModalOpen: boolean
  isNewVariableModalOpen: boolean
  initialValues: Values
}

const initialState: State = {
  currentVariableId: null,
  isEditVariableModalOpen: false,
  isNewVariableModalOpen: false,
  initialValues: {
    label: '',
    description: '',
    required: false,
    type: { id: VariableType.text },
    default: ''
  }
}

const reducer = (state: State, action: Action): State => action.apply(state)

const validateForm = (data: {
  label: string,
  description: string,
  [key: string]: unknown
}, context: {
  takenLabels: string[]
  initialValues: Values
}): object => {
  let errors = {}

  return errors
}

const errorToFormErrors = (error: ErrorDescription | Error) => {
  if ("errors" in error) {
    return Object.fromEntries(
      Object.entries(error.errors)
        .map(([key, value]) => [key, value.map(({ message }) => message)])
    )
  }

  return { error: error.message }
}

const EditVariables = (): JSX.Element => {
  const classes = useStyles()
  const { templateId } = useParams()
  const { core } = useAppContext()

  const { variables, isLoading, error } = useListVariables(templateId)

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const [{
    isNewVariableModalOpen,
    isEditVariableModalOpen,
    currentVariableId,
    initialValues
  }, dispatch] = useReducer(reducer, initialState)

  const handleGenericError = (title: string, description: string = '') => {
    core.showFlag({
      id: title,
      type: 'error',
      title,
      description
    })
  }

  const handleNewVariableClick = (type: VariableType) => {
    dispatch(new NewVariableModalRequested(type))
  }

  const handleEditVariableClick = (id: string) => {
    dispatch(new EditVariableModalRequested(id))
  }

  const handleCloseDialogClick = () => {
    dispatch(new CloseDialogRequested())
  }

  const handleVariableCreated = () => {
    dispatch(new CloseDialogRequested())
  }

  const handleVariableUpdated = () => {
    dispatch(new CloseDialogRequested())
  }

  const handleDeleteVariableClick = (id: string) => {
    confirm('Are you sure? The variable and all its references in the issue fields will be removed') && deleteVariable({ id })
  }

  const { createVariable } = useCreateVariable({
    templateId
  })

  const { updateVariable, isUpdating } = useUpdateVariable({
    templateId,
    onSuccess: handleVariableUpdated,
  })

  const { deleteVariable } = useDeleteVariable({
    templateId,
  })

  const { rankVariable } = useRankVariable({
    templateId,
    onError: handleGenericError
  })

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <ErrorState error={error} />
  }

  const noVariables = variables.length === 0
  const currentVariable = variables.find(({ id }) => id === currentVariableId)

  const actionsContent = (
    <ButtonGroup>
      <AddButton onClick={handleNewVariableClick} />
    </ButtonGroup>
  )

  const bottomBarContent = (
    <div>Variables are dynamic placeholders in templates that collect user input during Jira issue creation, boosting efficiency and accuracy. <Link href="https://go.appliger.com/variables">Learn more about Variables</Link></div>
  )

  const takenLabels = variables.map(({ label }) => label.toLowerCase())

  const handleReorder = async (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const newIndex = variables.findIndex(({ id }) => id === over.id)
      const oldIndex = variables.findIndex(({ id }) => id === active.id)

      const params = newIndex < oldIndex ?
        { id: active.id, before: over.id } :
        { id: active.id, after: over.id }

      try {
        return await rankVariable(params)
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <>
      <PageHeader actions={actionsContent} bottomBar={bottomBarContent}>Form & Variables</PageHeader>
      <Tabs id="variables-tabs">
        <TabList>
          <Tab>Variables</Tab>
          <Tab>Form Preview</Tab>
        </TabList>
        <TabPanel>
          <div className={classes.content}>
            {noVariables ? (
              <Empty />
            ) : (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleReorder}
              >
                <SortableContext
                  items={variables}
                  strategy={verticalListSortingStrategy}
                >
                  <List
                    variables={variables}
                    onSelectItem={handleEditVariableClick}
                    onDeleteItem={handleDeleteVariableClick}
                  />
                </SortableContext>
              </DndContext>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className={classes.content}>
            {noVariables ? (
              <Empty />
            ) : (
              <Preview variables={variables} />
            )}
          </div>
        </TabPanel>
      </Tabs>
      <LogOnMount eventType={UIEvent.VARIABLES_EDIT_PAGE_VISITED} />
      {isNewVariableModalOpen && (
        <Form<Values>
          onSubmit={async (values) => {
            const errors = validateForm(values, {
              initialValues, takenLabels
            })

            if (Object.keys(errors).length > 0) {
              return errors
            }

            const defaultValue = values.default && typeof values.default === "object" && 'value' in values.default ?
              values.default.value :
              values.default

            const params = {
              label: values.label,
              description: values.description,
              type: values.type.id,
              default: defaultValue,
              required: values.required,
              config: values.config
            }

            try {
              await createVariable(params)

              handleVariableCreated()
            } catch (error) {
              return errorToFormErrors(error)
            }
          }}
        >
          {({ formProps, setFieldValue }) => (
            <Dialog
              heading="New Variable"
              submitLabel="Create"
              onClose={handleCloseDialogClick}
              formProps={formProps}
              testId="new-variable-form"
            >
              <VariableForm takenLabels={takenLabels} initialValues={initialValues} setFieldValue={setFieldValue} />
            </Dialog>
          )}
        </Form >)
      }
      {isEditVariableModalOpen && (
        <Form<Values>
          isDisabled={isUpdating}
          onSubmit={async (formValues) => {
            const initialValues = {
              label: currentVariable.label,
              description: currentVariable.description,
              type: { id: currentVariable.type },
              default: currentVariable.default,
              required: currentVariable.required,
              config: currentVariable.config
            }

            const errors = validateForm(formValues, {
              initialValues,
              takenLabels
            })

            if (Object.keys(errors).length > 0) {
              return errors
            }

            const defaultValue = formValues.default && typeof formValues.default === "object" && 'value' in formValues.default ?
              formValues.default.value :
              formValues.default

            const values = {
              label: formValues.label,
              description: formValues.description,
              type: formValues.type.id,
              default: defaultValue,
              required: formValues.required,
              config: formValues.config
            }

            forEach(['label', 'description', 'default'], (key) => {
              if (values[key] === initialValues[key]) {
                delete values[key]
              }
            })

            if (values.type === initialValues.type.id) {
              delete values.type
            }

            if (values.required === undefined || Boolean(values.required) === Boolean(initialValues.required)) {
              delete values.required
            }

            if (JSON.stringify(values.config) === JSON.stringify(initialValues.config)) {
              delete values.config
            }

            try {
              await updateVariable({
                id: currentVariableId,
                values
              })

              handleVariableUpdated()
            } catch (error) {
              return errorToFormErrors(error)
            }
          }}
        >
          {({ formProps, setFieldValue }) => (
            <Dialog
              heading="Edit Variable"
              submitLabel="Save"
              onClose={handleCloseDialogClick}
              formProps={formProps}
              testId="edit-variable-form"
            >
              <VariableForm
                initialValues={{
                  type: { id: currentVariable?.type },
                  label: currentVariable?.label,
                  description: currentVariable?.description,
                  required: currentVariable?.required,
                  default: currentVariable?.default,
                  config: currentVariable?.config,
                }}
                takenLabels={takenLabels}
                setFieldValue={setFieldValue}
              />
            </Dialog>
          )}
        </Form >)
      }
    </>
  )
}

export default EditVariables
