// FIXME: DRY! Many things are copiend from New Issue/ useHandlers
import sortBy from "lodash/sortBy"
import { StringField } from "@easy-templates/lib"
import { Template, TemplateVariable, Job } from "@easy-templates/types"

import { Item as ProgressItem } from "components/ui/progress"

type TemplatesIndexItem = Omit<Template, 'tree' | 'issues'>
type VarialbeValues = { [key: string]: unknown }

export type State = {
  isLoadingPermissions?: boolean
  isProcessing?: boolean,
  mustMatchIssueType: boolean
  errorMessage?: string
  query?: string
  selectedTemplateId?: string
  selectedTemplate?: Template
  showVariablesForm?: boolean
  variableValues: VarialbeValues
  variables: TemplateVariable[]
  showResults: boolean
  results: ProgressItem[]
  jobId?: string
}

class BaseAction {
  apply(state: State): State {
    console.error("Action not implemented!")
    return state
  }
}

export class TemplateLoaded extends BaseAction {
  template: Template

  constructor(template: Template) {
    super()
    this.template = template
  }

  apply(state: State): State {
    const hasVariables = this.template.variables.length > 0
    const showResults = Object.keys(this.template.issues).length > 1 && this.template.createChildren && !hasVariables
    const results = buildResultItems([this.template.rootIssueId], this.template, state.variableValues, {})

    return {
      ...state,
      selectedTemplate: this.template,
      variables: this.template.variables,
      showVariablesForm: hasVariables,
      showResults,
      results
    }
  }
}

export class TemplateIsSelected extends BaseAction {
  template: TemplatesIndexItem

  constructor(template: Template) {
    super()
    this.template = template
  }

  apply(state: State): State {
    return {
      ...state,
      selectedTemplateId: this.template.id,
    }
  }
}

export class TemplateIsUnselected extends BaseAction {

  apply(state: State): State {
    return {
      ...state,
      selectedTemplateId: undefined,
      selectedTemplate: undefined,
      showVariablesForm: false
    }
  }
}

export class ApplyingStarted extends BaseAction {
  constructor(public jobId: string) {
    super()
  }

  apply(state: State): State {
    return {
      ...state,
      jobId: this.jobId
    }
  }
}

export class ApplyingRequested extends BaseAction {

  constructor(public template: Template, public variableValues: VarialbeValues) {
    super()
  }

  apply(state: State): State {
    const issueIds = this.template.createChildren ? Object.keys(this.template.issues) : [this.template.rootIssueId]
    const showResults = issueIds.length > 1
    const fakeResults = Object.fromEntries(issueIds.map(id => [id, {}]))

    const results = buildResultItems([this.template.rootIssueId], this.template, this.variableValues, fakeResults)

    return {
      ...state,
      isProcessing: true,
      results,
      showResults,
      variableValues: this.variableValues,
      showVariablesForm: false
    }
  }
}

export class ApplyingFinished extends BaseAction {
  constructor(public results: {}, public onClick: (params: { key: string }) => void) {
    super()
  }

  apply(state: State): State {
    const results = buildResultItems(
      [state.selectedTemplate.rootIssueId],
      state.selectedTemplate,
      state.variableValues,
      this.results,
      null,
      this.onClick
    )

    return {
      ...state,
      isProcessing: false,
      results
    }
  }
}

export class ApplyingFailed extends BaseAction {
  constructor(public message: string) {
    super()
  }

  apply(state: State): State {
    const results = buildResultItems(
      [state.selectedTemplate.rootIssueId],
      state.selectedTemplate,
      state.variableValues,
      {},
      this.message
    )

    return {
      ...state,
      isProcessing: false,
      results,
      errorMessage: state.showResults ? null : this.message
    }
  }
}

const buildResultItems = (
  itemIds: string[],
  template: Template,
  variableValues: State["variableValues"],
  results: {},
  errorMessage?: string,
  onClick?: ({ key }) => void
) => {
  if (!itemIds?.length || !template) return []

  const items = itemIds
    .map((id) => {
      const issue = template.issues[id]
      const result = errorMessage
        ? {
          status: "error",
          result: results[issue.id] || errorMessage,
        }
        : results[issue.id]

      if (!result) {
        return
      }

      const summary = new StringField(
        { body: issue.name, name: "summary" },
        { variableValues }
      )

      return {
        id: issue.id,
        iconUrl: issue.iconUrl,
        title: summary.value(),
        message: result?.status === "error" ? result?.result : "",
        children: buildResultItems(
          template.tree[id],
          template,
          variableValues,
          results,
          errorMessage,
          onClick
        ),
        rank: issue.rank,
        status: result?.status,
        onClick: () => {
          if (result.result && onClick) {
            onClick({ key: result.result })
          }
        },
      }
    }).filter(Boolean)

  return sortBy(items, "rank")
}

export class MatchIssueTypeChanged extends BaseAction {
  constructor(public mustMatchIssueType: boolean) {
    super()
  }

  apply(state: State): State {
    return {
      ...state,
      mustMatchIssueType: this.mustMatchIssueType,
    }
  }
}

export class QueryChangedAction extends BaseAction {
  constructor(public query: string) {
    super()
  }

  apply(state: State): State {
    return {
      ...state,
      query: this.query.toLowerCase(),
    }
  }
}

export class JobUpdatedAction extends BaseAction {
  constructor(public job: Job) {
    super()
  }

  apply(state: State): State {
    console.debug("Results before", state.results)

    const results = buildResultItems(
      [state.selectedTemplate.rootIssueId],
      state.selectedTemplate,
      state.variableValues,
      this.job.results || {}
    )

    console.debug("Results after", results)

    return {
      ...state,
      results,
      jobId: this.job.status === "in_progress" ? this.job.id : null,
    }
  }
}

const reducer = (state: State, action: BaseAction): State =>
  action.apply(state)

export default reducer
