import { WithEditorActions } from '@atlaskit/editor-core'
import { EditorContext } from '@atlaskit/editor-core/editor-context'
import { ComposableEditor } from '@atlaskit/editor-core/composable-editor'
import { EditorPresetBuilder } from '@atlaskit/editor-common/preset'
import { useUniversalPreset } from '@atlaskit/editor-core/preset-universal'

// Editor plugins
import { basePlugin } from '@atlaskit/editor-plugins/base'
import { widthPlugin } from '@atlaskit/editor-plugins/width'
import { decorationsPlugin } from '@atlaskit/editor-plugins/decorations'
import { extensionPlugin } from '@atlaskit/editor-plugins/extension'
import { typeAheadPlugin } from '@atlaskit/editor-plugins/type-ahead'
import { quickInsertPlugin } from '@atlaskit/editor-plugin-quick-insert'

import { usePreset } from '@atlaskit/editor-core/use-preset'

import { WikiMarkupTransformer } from '@atlaskit/editor-wikimarkup-transformer'

import Textfield, { TextFieldProps } from "components/ui/Textfield"
import { ButtonProps, IconButton } from "components/ui/Button"
import InlineMessage from "components/ui/InlineMessage"
import Tooltip from "components/ui/Tooltip"

import {
  Icon as AddonIcon,
  buildDocItem,
  quickInsertProviderFactory,
} from './extension'

type Props = TextFieldProps & {
  autoFocus?: boolean,
  allowInsertVariable?: boolean,
  onVariableRequest?: (onAddVariable: (label: string) => void) => void
  errorMessage?: string,
  inputRef?: React.RefObject<HTMLInputElement>,
}

type EditorProps = Props & {
  preset: EditorPresetBuilder,
}

const ErrorMessage = ({ message }: { message: string }) => {
  if (!message) {
    return null
  }

  return (
    <InlineMessage appearance='error' secondaryText={message}>
      {message}
    </InlineMessage>
  )
}

const ToolbarAddVariableButton = (buttonProps: Pick<ButtonProps, "onClick">) =>
  <Tooltip content="Insert Variable">
    {(tooltipProps) => (
      <IconButton
        {...tooltipProps}
        appearance='subtle'
        spacing='compact'
        icon={(iconProps) => <AddonIcon {...iconProps} size='medium' label="Insert Variable" />}
        testId='toolbar-add-variable-button'
        label="Insert Variable"
        {...buttonProps}
      />
    )}
  </Tooltip>

const Editor = ({ allowInsertVariable, preset, value, onChange, onVariableRequest, errorMessage, testId, autoFocus }: EditorProps) => {
  return (
    <div data-testid={testId}>
      <EditorContext>
        <WithEditorActions
          render={(editorActions) => {
            const injectVariable = (name: string) =>
              editorActions.replaceSelection(buildDocItem(name), true)

            const quickInsertProvider = quickInsertProviderFactory({
              onAdd: () => {
                onVariableRequest(injectVariable)
              }
            })

            const handleChange = () => {
              editorActions.getValue().then((value) => {
                onChange(value)
              })
            }

            const variablesProps = allowInsertVariable ? {
              primaryToolbarComponents: <ToolbarAddVariableButton onClick={() => onVariableRequest(injectVariable)} />,
              quickInsert: { provider: Promise.resolve(quickInsertProvider) }
            } : {}

            if (autoFocus) {
              editorActions.focus()
            }

            return (
              /* @ts-ignore: TODO: Fix quick insert */
              <ComposableEditor
                contentTransformerProvider={schema => new WikiMarkupTransformer(schema)}
                defaultValue={value}
                onChange={handleChange}
                preset={preset}
                {...variablesProps}
              />
            )
          }}
        />

      </EditorContext>
      <ErrorMessage message={errorMessage} />
    </div>
  )
}

export const RichTextEditor = (props: Props) => {
  const universalPreset = useUniversalPreset(
    {
      props: {
        allowStatus: true,
        allowTextColor: true,
        allowTextAlignment: true,
        allowDate: true,
        allowPanel: true,
        allowTables: true,
      }
    }
  )

  return <Editor preset={universalPreset} {...props} />
}

export const PlainTextEditor = (props: Props) => {
  const createPreset = () =>
    new EditorPresetBuilder()
      .add(basePlugin)
      .add(typeAheadPlugin)
      .add(quickInsertPlugin)
      .add(widthPlugin)
      .add(decorationsPlugin)
      .add(extensionPlugin)

  const { preset } = usePreset(createPreset)

  return <Editor preset={preset} {...props} />
}

export const TextEditor = ({
  allowInsertVariable,
  errorMessage,
  isDisabled,
  onVariableRequest,
  onChange,
  inputRef,
  ...fieldProps
}: Props) => {
  const addToInput = (label: string) => {
    if (!inputRef.current) {
      return
    }

    const textarea = inputRef.current

    let start_position = textarea.selectionStart
    let end_position = textarea.selectionEnd

    const newValue = `${textarea.value.substring(
      0,
      start_position
    )}<<${label}>>${textarea.value.substring(
      end_position,
      textarea.value.length
    )}`

    textarea.value = newValue
    // @ts-ignore: It expect an Event, but it work with a value
    onChange?.(newValue)
  }

  const variablesProps = allowInsertVariable ? {
    elemAfterInput: (<IconButton
      icon={AddonIcon}
      onClick={() => onVariableRequest(addToInput)}
      label="Insert Variable:"
    />)
  } : {}


  return (
    <>
      <Textfield
        {...fieldProps}
        ref={inputRef}
        onChange={onChange}
        autoFocus={true}
        isDisabled={isDisabled}
        {...variablesProps}
      />
      <ErrorMessage message={errorMessage} />
    </>
  )
}
