/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateUseRequest
 */
export interface TemplateUseRequest {
    /**
     * ID of the project in Jira where the issues will be created
     * @type {string}
     * @memberof TemplateUseRequest
     */
    projectId: string;
    /**
     * A map of variable names and values
     * @type {{ [key: string]: string; }}
     * @memberof TemplateUseRequest
     */
    variables?: { [key: string]: string; };
    /**
     * Key of the Jira issue the template is used on
     * @type {string}
     * @memberof TemplateUseRequest
     */
    rootIssueKey?: string;
    /**
     * List of issue IDs to be created in Jira
     * @type {Array<string>}
     * @memberof TemplateUseRequest
     */
    issueIds?: Array<string>;
}

/**
 * Check if a given object implements the TemplateUseRequest interface.
 */
export function instanceOfTemplateUseRequest(value: object): value is TemplateUseRequest {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    return true;
}

export function TemplateUseRequestFromJSON(json: any): TemplateUseRequest {
    return TemplateUseRequestFromJSONTyped(json, false);
}

export function TemplateUseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateUseRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['projectId'],
        'variables': json['variables'] == null ? undefined : json['variables'],
        'rootIssueKey': json['rootIssueKey'] == null ? undefined : json['rootIssueKey'],
        'issueIds': json['issueIds'] == null ? undefined : json['issueIds'],
    };
}

export function TemplateUseRequestToJSON(value?: TemplateUseRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'projectId': value['projectId'],
        'variables': value['variables'],
        'rootIssueKey': value['rootIssueKey'],
        'issueIds': value['issueIds'],
    };
}

