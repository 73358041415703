/**
 The Core module represents the Business Logic of the application.
 The UI must talk only to this class.
 It provides Jira and Templates ports (interfaces) to be implemented
*/

import {
  Job, ApplyingResult, FieldBody, TemplateIssue, TemplateVariable,
  TemplateScope,
  Jira,
  TelemetryInterface,
  VariableValues,
  VariableValue,
  Template,
} from "@easy-templates/types"

import { SilentTelemetry } from "./telemetry"

import { Result } from "./result"
import PerfTimer from "./perf-timer"
import Field from "./jira/field"

type Extra = { [key: string]: unknown }

type VariableConfig = {
  options?: string[]
}

export interface CoreInterface {
  // Templates
  applyTemplate: (params: {
    id: string
    variableValues: VariableValues
    issueKey: string
    projectId: string
  }) => Promise<ApplyingResult | { jobId: string }>

  copyTemplateIssue: (
    templateId: string,
    issueId: string,
    name: string
  ) => Promise<Result<TemplateIssue>>

  createFromTemplate: (data: CreateIssuesData) => Promise<Job | { jobId?: string, error?: string }>
  createTemplateFromIssue: (
    idOrKey: string,
    name: string,
    createChildren: boolean
  ) => Promise<Result<{ id: string, name: string, createChildren?: boolean }>>

  getApplyFormData: (params: { projectId: string }) =>
    Promise<{ templates: Template[], hasPermissions: boolean }>

  getIssueFormData: () =>
    Promise<{ templates: Template[], projects: Jira.Project[] }>

  getTemplatesList: (params: { projectId: string }) =>
    Promise<Template[]>

  getIssuePrefillData: (params: {
    templateId: string
    projectId: string
    variableValues: VariableValues
  }) => Promise<{ [fieldName: string]: unknown }>

  getTemplate: (id: string) => Promise<Template>

  getTemplateIssue: (templateId: string, issueId: string, projectId?: string) => Promise<TemplateIssue>

  updateTemplate: (id: string, params: {
    name?: string
    createChildren?: boolean
    scope?: string
    scopeValue?: string[]
  }) => Promise<Result<{
    id: string,
    name: string,
    createChildren: boolean,
    scope?: Template['scope']
    scopeValue?: string[]
  }>>

  deleteTemplate: (id: string) =>
    Promise<string>

  deleteTemplateIssue: (templateId: string, issueId: string) =>
    Promise<void>

  deleteTemplateVariable: (templateId: string, variableId: string) =>
    Promise<void>

  createTemplateVariable(templateId: string, attributes: {
    label: string,
    description: string,
    required: boolean,
    default?: VariableValue
    config?: VariableConfig
  }): Promise<Result<TemplateVariable>>

  updateTemplateVariable(templateId: string, id: string, attributes: {
    label?: string,
    description?: string,
    required?: boolean,
  }): Promise<Result<TemplateVariable>>

  getTemplateVariables(templateId: string): Promise<TemplateVariable[]>

  copyTemplate: (id: string, name: string) =>
    Promise<Result<{ id: string, name: string }>>

  updateTemplateIssueField: (
    templateId: string,
    issueId: string,
    name: string,
    value: string | number | { [key: string]: unknown }
  ) => Promise<Result<{ id: string, name: string, isDisabled: boolean }>>

  toggleTemplateIssueField: (
    templateId: string,
    issueId: string,
    name: string,
    isEnabled: boolean
  ) => Promise<Result<{ id: string, name: string, isDisabled: boolean }>>

  getJob: (id: string) =>
    Promise<Job>

  // Jira

  getIssue: (id: string) => Promise<Jira.IssueBean>
  getProjects: (params: { id?: string[] }) => Promise<Jira.Project[]>
  closeNewIssueDialog: () => void
  closeApplyTemplateDialog: () => void
  closeSaveAsTemplateDialog: () => void

  openCreateIssueDialog: (
    context: { [field: string]: FieldBody },
    onClose?: (createdIssues: Jira.IssueBean[]) => void
  ) => void

  openExternalLink: (path: string) => void
  refreshIssuePage: () => void
  request: <T = unknown>(url: string, options?: RequestInit) => Promise<T>
  resizeView: () => void
  showIssueCreatedMessage: ({ issueKey }: { issueKey: string }) => void
  navigateToIssue: ({ key }: { key: string }) => void
  initializeTheming: () => void

  getIssuePickerSuggestions: (
    query: string
  ) => Promise<Jira.SuggestionsResult>

  normalizeImageUrl: (url: string) => string

  showFlag(options: {
    id: string
    title?: string
    description: string
    type?: "error" | "success" | "warning" | "info"
    actions?: { text: string; onClick: () => void }[]
  }): void

  getUser(accountId: string): Promise<Jira.User>
}

interface Validable {
  isValid(): boolean
}

export class CreateIssuesData implements Validable {
  constructor(public data: {
    templateId: string,
    projectId: string,
    variableValues: VariableValues,
    issueIds: string[],
    rootIssueKey?: string,
  }) {

  }

  isValid(): boolean {
    if (!this.data.templateId || !this.data.projectId) {
      return false
    }

    return true
  }
}

export interface TemplatesAdapterInterface {
  createFromIssue({
    sourceId,
    name,
    createChildren,
  }: {
    sourceId: string
    name: string
    createChildren: boolean
  }): Promise<Result<{ id: string, name: string, createChildren?: boolean }>>

  // Template

  all(): Promise<Template[]>
  get(id: string): Promise<Template>
  update(
    id: string,
    data: {
      name?: string
      createChildren?: boolean
      scope?: string
      scopeValue?: string[]
    }
  ): Promise<Result<{ id: string, name: string, createChildren: boolean, scope: Template['scope'], scopeValue: string[] }>>
  copy(id: string, name: string): Promise<Result<{ id: string, name: string, createChildren?: boolean }>>
  delete(id: string): Promise<string>

  applyToIssue(id: string, issueJiraKey: string, projectId: string, variableValues: VariableValues): Promise<ApplyingResult | { jobId: string }>
  createIssuesFromTemplate(data: CreateIssuesData): Promise<{ jobId?: string; error?: string } | Job>

  // Issue

  getIssue(templateId: string, id: string): Promise<TemplateIssue>
  getIssues(templateId: string): Promise<TemplateIssue[]>
  deleteIssue(templateId: string, id: string): Promise<void>

  copyIssue(
    templateId: string,
    issueId: string,
    name: string
  ): Promise<Result<TemplateIssue>>

  updateIssueField(
    templateId: string,
    issueId: string,
    name: string,
    value: unknown
  ): Promise<Result<{ id: string, name: string, isDisabled: boolean }>>

  toggleIssueField(
    templateId: string,
    issueId: string,
    name: string,
    isEnabled: boolean
  ): Promise<Result<{ id: string, name: string, isDisabled: boolean }>>

  // Jobs

  getJob(id: string): Promise<Job>

  /*
    Variables
  */

  deleteVariable(templateId: string, id: string): Promise<void>

  createVariable(templateId: string, attributes: {
    label: string,
    description: string,
    required: boolean,
  }): Promise<Result<TemplateVariable>>

  updateVariable(templateId: string, id: string, attributes: {
    label?: string,
    description?: string,
    required?: boolean,
    default?: VariableValue
  }): Promise<Result<TemplateVariable>>

  getVariables(templateId: string): Promise<TemplateVariable[]>
  rankVariable(templateId: string, id: string, target: { after: string } | { before: string }): Promise<Result<undefined>>
}

export interface PlatformAdapterInterface {
  getIssue(id: string): Promise<Jira.IssueBean>
  getProjects(params: { id?: string[] }): Promise<Jira.Project[]>
  getPermittedProjects(): Promise<Jira.Project[]>
  closeNewIssueDialog(): void
  closeApplyTemplateDialog(): void
  closeSaveAsTemplateDialog(): void
  openCreateIssueDialog(
    context: { [field: string]: FieldBody },
    onClose?: (createdIssues: Jira.IssueBean[]) => void
  ): void
  openExternalLink(path: string): void
  refreshIssuePage(): void
  request<T = unknown>(url: string, options?: RequestInit): Promise<T>
  resize(): void
  showIssueCreatedMessage({ issueKey }: { issueKey: string }): void
  navigateToIssue({ key }: { key: string }): void
  initializeTheming(): void
  getIssuePickerSuggestions(query: string): Promise<Jira.SuggestionsResult>
  normalizeImageUrl(url: string): string
  showFlag(options: {
    id: string
    title?: string
    description: string
    type?: "error" | "success" | "warning" | "info"
    actions?: { text: string; onClick: () => void }[]
  }): void

  getCreateMeta(params: { projectIds: string[] }): Promise<Jira.IssueCreateMetadata>
  getUser(accountId: string): Promise<Jira.User>
}


export default class Core implements CoreInterface {
  platformAdapter: PlatformAdapterInterface
  templatesAdapter: TemplatesAdapterInterface
  telemetry: TelemetryInterface
  logger: PerfTimer

  constructor(platformAdapter: PlatformAdapterInterface, templatesAdapter: TemplatesAdapterInterface, telemetry?: TelemetryInterface) {
    this.platformAdapter = platformAdapter
    this.templatesAdapter = templatesAdapter
    this.telemetry = telemetry || new SilentTelemetry()
    this.logger = new PerfTimer("Easy Templates")
  }

  private async report(error: Error, extra: Extra) {
    this.logger.warn(error.message, error.stack)

    try {
      await this.telemetry.captureMessage(error.message, {
        stack: error.stack,
        ...extra,
      })
    } catch (error) {
      this.logger.debug("Telemetry Error", error.message)
    }
  }

  private async reportAndThrow(error: Error, extra: Extra = {}) {
    await this.report(error, extra)

    throw error
  }

  async applyTemplate(params: {
    id: string
    variableValues: VariableValues
    issueKey: string
    projectId: string
  }) {
    const { id, variableValues, issueKey, projectId } = params

    try {
      const results = await this.templatesAdapter.applyToIssue(
        id,
        issueKey,
        projectId,
        variableValues
      )

      await this.telemetry.trackEvent("Template Applied")

      return results
    } catch (error) {
      await this.report(error, params)

      throw error
    }
  }

  async copyTemplateIssue(
    templateId: string,
    issueId: string,
    name: string
  ) {
    try {
      const result = await this.templatesAdapter.copyIssue(
        templateId,
        issueId,
        name
      )

      await this.telemetry.trackEvent("Template Issue Copied")

      return result
    } catch (error) {
      await this.reportAndThrow(error, { templateId, issueId, name })
    }
  }

  async createFromTemplate(data: CreateIssuesData) {
    if (!data.isValid()) {
      return Promise.reject({ error: "Invalid data" })
    }

    const result = await this.templatesAdapter.createIssuesFromTemplate(data)

    await this.telemetry.trackEvent("Template Used")

    return result
  }

  async createTemplateFromIssue(
    idOrKey: string,
    name: string,
    createChildren: boolean
  ) {

    try {
      const result = await this.templatesAdapter.createFromIssue({
        sourceId: idOrKey,
        name,
        createChildren,
      })

      await this.telemetry.trackEvent("Template Created")

      return result
    } catch (error) {
      await this.reportAndThrow(error, { idOrKey, name, createChildren })
    }
  }

  async getApplyFormData(params: { projectId: string }) {
    this.logger.debug("getting Apply Form data", params)

    try {
      const permittedProjects = await this.platformAdapter.getPermittedProjects()

      const project = permittedProjects.find(
        ({ id }) => id == params.projectId
      )

      if (!project) {
        this.logger.debug("no permitted projects found")
        return { templates: [], hasPermissions: false }
      }

      const allTemplates = await this.templatesAdapter.all()
      const projectIssuetypeIds = project.issueTypes.map(({ id }) => id)

      const templates = allTemplates.filter(({ issuetype, scope, scopeValue }) => {

        // TODO: Find a way to make sure we know exactly the data type of the
        // fields of objected we get from the Core module or its' dependencies,
        // i.e. scopeValue or project id
        if (scope == TemplateScope.PROJECTS && !scopeValue.map(String).includes(String(params.projectId))) {
          return false
        }

        return projectIssuetypeIds.includes(issuetype.id)
      })

      return { templates, hasPermissions: true }
    } catch (error) {
      await this.reportAndThrow(error, params)
    }
  }

  async getIssueFormData() {
    try {
      console.time("Templates form data")
      const [allProjects, allTemplates] = await Promise.all([
        this.platformAdapter.getPermittedProjects(),
        this.templatesAdapter.all(),
      ])

      console.timeEnd("Templates form data")

      const templateIssuetypeIds = new Set(
        allTemplates.map(({ issuetype }) => issuetype.id)
      )

      const projectIssuetypeIds = new Set(
        allProjects.flatMap(({ issueTypes }) => issueTypes.map(({ id }) => id))
      )

      const projectIssuetypesMap = Object.fromEntries(
        allProjects.map(({ id, issueTypes }) => [
          id,
          issueTypes.map(({ id }) => id),
        ])
      )

      const templates = allTemplates.filter(({ issuetype }) =>
        projectIssuetypeIds.has(issuetype.id)
      )

      const projectsTemplatesMap = templates.reduce((map, template) => {
        if (template.scope === TemplateScope.GLOBAL) {
          return Object.fromEntries(
            Object.entries(map).map((item) => {
              const [projectId, templateIds] = item

              if (
                projectIssuetypesMap[projectId].includes(template.issuetype.id)
              ) {
                return [projectId, [...templateIds, template.id]]
              }

              return item
            })
          )
        }

        (template.scopeValue || []).forEach((projectId) => {
          if (
            map[projectId] &&
            projectIssuetypesMap[projectId].includes(template.issuetype.id)
          ) {
            map[projectId].push(template.id)
          }
        })

        return map
      }, Object.fromEntries(allProjects.map(({ id }) => [id, []])))

      const projects = allProjects.filter(
        ({ id }) => projectsTemplatesMap[id].length > 0
      )

      return { templates, projects }
    } catch (error) {
      await this.reportAndThrow(error)
    }
  }

  // Returns a list of the templates that can be used
  // in the project, the project id is provided via the
  // request context
  // If no project in the context then
  // it returns all "permitted" projects templates
  async getTemplatesList(params: { projectId: string }) {
    const { projectId } = params

    const projectsGetter = projectId
      ? this.platformAdapter.getProjects({ id: [projectId] })
      : this.platformAdapter.getPermittedProjects()

    try {
      const [projects, allTemplates] = await Promise.all([
        projectsGetter,
        this.templatesAdapter.all(),
      ])

      const projectIssuetypeIds = new Set(
        projects.flatMap(({ issueTypes }) => issueTypes.map(({ id }) => id))
      )

      const templates = allTemplates.filter(({ issuetype }) =>
        projectIssuetypeIds.has(issuetype.id)
      )

      return templates
    } catch (error) {
      await this.reportAndThrow(error, params)
    }
  }

  async getIssuePrefillData(params: {
    templateId: string
    projectId: string
    variableValues: VariableValues,
    issueTypeId?: string
  }) {
    const {
      templateId,
      projectId,
      variableValues,
    } = params
    try {
      const tmpl = await this.templatesAdapter.get(templateId)

      const issue = await this.getTemplateIssue(
        templateId,
        tmpl.rootIssueId,
        projectId,
      )

      const fieldEntries =
        // @ts-ignore
        Object.entries(issue.fields)
          .map((entry) => {
            const key = entry[0]
            const { body, meta, isDisabled } = entry[1]

            const field = Field.build(
              { isDisabled, body },
              // @ts-ignore
              meta,
              {
                projectId: projectId,
                variableValues,
              }
            )

            return [key, field]
          })
          .filter((entry) => {
            return (entry[1] as Field).isSubmitable()
          })
          .flatMap((entry) => {
            const key = entry[0]
            const field = entry[1] as Field
            const value = field.prefillValue()

            if (field.isCascading() && Array.isArray(value)) {
              return value.map((levelValue: string, level: number) => {
                const fieldName = level === 0 ? key : `${key}:${level}`

                return [fieldName, levelValue]
              })
            }

            return [[key, field.prefillValue()]]
          })

      const fields = Object.fromEntries(fieldEntries)

      return fields
    } catch (error) {
      await this.reportAndThrow(error, params)
    }
  }

  async getTemplate(id: string) {
    try {
      return await this.templatesAdapter.get(id)
    } catch (error) {
      await this.reportAndThrow(error, { id })
    }
  }

  async getTemplateIssue(
    templateId: string,
    issueId: string,
    contextProjectId?: string
  ) {
    try {
      console.debug({ templateId, issueId, contextProjectId })
      const issue = await this.templatesAdapter.getIssue(templateId, issueId)
      const projectId = contextProjectId || issue.fields.project.id
      const issuetypeId = issue.fields.issuetype.id

      const issueCreateMetaResult = await this.platformAdapter.getCreateMeta({
        projectIds: [projectId],
      })

      let projectCreateMeta = issueCreateMetaResult.projects
        .find(({ id }) => id === projectId)

      if (!projectCreateMeta) {
        projectCreateMeta = issueCreateMetaResult.projects
          .find(({ issuetypes }) => {
            issuetypes.find(({ id }) => id === issuetypeId)
          })
      }

      if (!projectCreateMeta) {
        throw new Error(`No meta data available for the Project (ID: ${projectId})`)
      }

      const issueCreateMeta = projectCreateMeta
        .issuetypes.find(({ id }) => {
          console.debug({ id, issuetypeId })
          return id === issuetypeId
        })

      console.debug({ issueCreateMeta })

      const fields = Object.fromEntries(
        Object.entries(issueCreateMeta?.fields || {}).map((fieldEntry) => {
          const fieldKey = fieldEntry[0]
          const meta = fieldEntry[1] as Jira.FieldMetadata

          return [
            fieldKey,
            {
              body: issue.fields[fieldKey],
              isDisabled: issue.disabledFields.includes(fieldKey),
              id: fieldKey,
              meta,
              name: meta.name,
              isVirtual: !issue.fields.hasOwnProperty(fieldKey),
            },
          ]
        })
      ) as TemplateIssue["fields"]

      console.debug({ fields })

      return { ...issue, fields }
    } catch (error) {
      await this.reportAndThrow(error, { issueId, templateId, contextProjectId })
    }
  }

  async updateTemplate(id: string, params: {
    name?: string
    createChildren?: boolean
    scope?: string
    scopeValue?: string[]
  }) {
    try {
      const result = await this.templatesAdapter.update(id, params)

      await this.telemetry.trackEvent("Template Updated")

      return result
    } catch (error) {
      await this.reportAndThrow(error, { id, params })
    }
  }

  async deleteTemplate(id: string) {
    try {
      const result = await this.templatesAdapter.delete(id)

      await this.telemetry.trackEvent("Template Deleted")

      return result
    } catch (error) {
      await this.reportAndThrow(error, { id })
    }
  }

  async deleteTemplateIssue(templateId: string, issueId: string) {
    try {
      await this.templatesAdapter.deleteIssue(
        templateId,
        issueId
      )

      await this.telemetry.trackEvent("Template Issue Deleted")

    } catch (error) {
      await this.reportAndThrow(error, { templateId, issueId })
    }
  }


  async copyTemplate(id: string, name: string) {
    try {
      const result = await this.templatesAdapter.copy(id, name)

      await this.telemetry.trackEvent("Template Copied")

      return result
    } catch (error) {
      await this.reportAndThrow(error, { id, name })
    }
  }

  async updateTemplateIssueField(
    templateId: string,
    issueId: string,
    name: string,
    value: unknown
  ) {
    try {
      const result = await this.templatesAdapter.updateIssueField(
        templateId,
        issueId,
        name,
        value
      )

      await this.telemetry.trackEvent("Template Issue Field Updated")

      return result
    } catch (error) {
      await this.reportAndThrow(error, { templateId, issueId, name, value })
    }
  }

  async toggleTemplateIssueField(
    templateId: string,
    issueId: string,
    name: string,
    isEnabled: boolean
  ) {
    try {
      const result = await this.templatesAdapter.toggleIssueField(
        templateId,
        issueId,
        name,
        isEnabled
      )

      const message = isEnabled
        ? "Template Issue Field Enabled"
        : "Template Issue Field Disabled"

      await this.telemetry.trackEvent(message)

      return result
    } catch (error) {
      await this.reportAndThrow(error, { templateId, issueId, name, isEnabled })
    }
  }

  // Jobs
  async getJob(id: string) {
    return await this.templatesAdapter.getJob(id)
  }

  // Variables
  async deleteTemplateVariable(templateId: string, variableId: string) {
    try {
      const result = await this.templatesAdapter.deleteVariable(
        templateId,
        variableId
      )

      await this.telemetry.trackEvent("Template Variable Deleted")

      return result
    } catch (error) {
      await this.reportAndThrow(error, { templateId, variableId })
    }
  }

  async getTemplateVariables(templateId: string) {
    try {
      return await this.templatesAdapter.getVariables(templateId)
    } catch (error) {
      await this.reportAndThrow(error, { templateId })
    }
  }

  async createTemplateVariable(templateId: string, attributes: {
    label: string,
    description: string,
    required: boolean,
    config?: VariableConfig
  }) {
    try {
      const result = await this.templatesAdapter.createVariable(templateId, attributes)

      await this.telemetry.trackEvent("Template Variable Created")

      return result
    } catch (error) {
      await this.reportAndThrow(error, { templateId, attributes })
    }
  }

  async updateTemplateVariable(templateId: string, id: string, attributes: { label?: string; description?: string; required?: boolean, default?: string }) {
    try {
      const result = await this.templatesAdapter.updateVariable(templateId, id, attributes)

      await this.telemetry.trackEvent("Template Variable Updated")

      return result
    } catch (error) {
      await this.reportAndThrow(error, { templateId, id, attributes })
    }
  }

  async rankTemplateVariable(templateId: string, id: string, target: { after: string } | { before: string }) {
    try {
      const result = await this.templatesAdapter.rankVariable(templateId, id, target)

      await this.telemetry.trackEvent("Template Variable Updated")

      return result
    } catch (error) {
      await this.reportAndThrow(error, { templateId, id, target })
    }
  }

  // Jira
  getIssue(id: string) {
    return this.platformAdapter.getIssue(id)
  }

  async getProjects(params: { id?: string[] }) {
    return this.platformAdapter.getProjects(params)
  }

  closeNewIssueDialog() {
    this.platformAdapter.closeNewIssueDialog()
  }

  closeApplyTemplateDialog() {
    this.platformAdapter.closeApplyTemplateDialog()
  }

  closeSaveAsTemplateDialog() {
    this.platformAdapter.closeSaveAsTemplateDialog()
  }

  openCreateIssueDialog(
    context: { [field: string]: FieldBody },
    onClose?: (createdIssues: Jira.IssueBean[]) => void
  ) {
    this.platformAdapter.openCreateIssueDialog(context, onClose)
  }

  openExternalLink(path: string) {
    this.platformAdapter.openExternalLink(path)
  }

  refreshIssuePage() {
    this.platformAdapter.refreshIssuePage()
  }

  request<T = unknown>(url: string, options?: RequestInit) {
    return this.platformAdapter.request<T>(url, options)
  }

  resizeView() {
    console.debug("Resizing the iframe")
    this.platformAdapter?.resize()
  }

  showIssueCreatedMessage({ issueKey }: { issueKey: string }) {
    this.platformAdapter.showIssueCreatedMessage({ issueKey })
  }

  navigateToIssue({ key }: { key: string }) {
    this.platformAdapter.navigateToIssue({ key })
  }

  initializeTheming() {
    this.platformAdapter.initializeTheming()
  }

  getIssuePickerSuggestions(query: string) {
    return this.platformAdapter.getIssuePickerSuggestions(query)
  }

  normalizeImageUrl(url: string) {
    return this.platformAdapter.normalizeImageUrl(url)
  }

  showFlag(options: {
    id: string
    title?: string
    description: string
    type?: "error" | "success" | "warning" | "info"
    actions?: { text: string; onClick: () => void }[]
  }) {
    this.platformAdapter.showFlag(options)
  }

  getUser(accountId: string) {
    return this.platformAdapter.getUser(accountId)
  }
}
