import { parseISO, formatISO } from 'date-fns'
import { VariableType } from '@easy-templates/types'
import Textfield, { TextFieldProps } from 'components/ui/Textfield'
import Textarea, { TextAreaProps } from 'components/ui/Textarea'
import { DatePicker, DatePickerProps } from 'components/ui/DatetimePicker'
import Select, { SelectProps } from 'components/ui/Select'

import { fieldNameRealToForm } from './names-transformer'

type Value = string | number | readonly string[] | { value: string } | undefined

type FormEvent = {
  currentTarget: {
    value: Value
  }
}

class BaseValidation<T> {
  isRequired: boolean
  constructor(isRequired: boolean = false) {
    this.isRequired = isRequired
  }

  validate = (_value: T): string | undefined => {
    return undefined
  }
}

class TextValidation extends BaseValidation<string> {
  validate = (rawValue: unknown) => {
    const value = String(rawValue)

    if (!value && this.isRequired) {
      return "Cannot be blank"
    }
  }
}

class ShortTextValidation extends BaseValidation<string> {
  validate = (rawValue: unknown) => {
    const error = new TextValidation(this.isRequired).validate(rawValue)

    if (error) {
      return error
    }
  }
}

class NumberValidation extends BaseValidation<number> {
  validate = (value: number | undefined) => {
    if (value === undefined && this.isRequired) {
      return "Cannot be blank"
    }

    if (typeof value === 'number' && (Number.isNaN(value) || value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY)) {
      return "Must be a number"
    }
  }
}

class DateValidation extends BaseValidation<string> {
  validate = (value: unknown) => {
    if (value === undefined && this.isRequired) {
      return "Cannot be blank"
    }

    if (value === undefined) {
      return undefined
    }

    if (typeof value !== 'string') {
      return "Must be a date"
    }


    if (!parseISO(value)) {
      return "Must be a date"
    }
  }
}

class SelectValidation extends BaseValidation<{ value: string }> {
  constructor(isRequired: boolean = false, public config: { options?: string[] } = {}) {
    super(isRequired)
  }

  validate = (option: unknown) => {
    if (!option && this.isRequired) {
      return "Cannot be blank"
    }

    if (!option) {
      return undefined
    }

    if (!(typeof option === 'object' && 'value' in option)) {
      return "Invalid option"
    }

    if (!this.config.options?.includes(option.value as string)) {
      return "Invalid option"
    }
  }
}

// Transformers

class BaseTransformer {
  isRequired: boolean
  constructor(isRequired: boolean = false) {
    this.isRequired = isRequired
  }

  transform(event: FormEvent | Value): Value {
    if (event && typeof event === 'object' && 'currentTarget' in event) {
      return event.currentTarget.value
    }

    return event as Value
  }
}

class TextTransformer extends BaseTransformer {
  transform = (event: FormEvent | Value): string => {
    const value = super.transform(event)

    if (value === undefined || value === null) {
      return ''
    }

    if (typeof value === 'number' && (Number.isNaN(value) || value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY)) {
      return ''
    }

    if (typeof value === 'object') {
      return 'value' in value ? value.value : ''
    }

    return String(value)
  }
}

class ShortTextTransformer extends BaseTransformer {
  transform = (event: FormEvent | Value): string => {
    const value = new TextTransformer(this.isRequired).transform(event)

    return value.replace(/[\r\n]+/g, ' ')
  }
}

class NumberTransformer extends BaseTransformer {
  transform = (event: FormEvent | Value): number | undefined => {
    const value = super.transform(event)

    if (typeof value === 'number') {
      if (isNaN(value)) {
        return undefined
      }

      return value
    }

    if (typeof value === 'string') {
      if (!value) { return undefined }

      const result = Number(value)

      if (isNaN(result)) {
        return undefined
      }

      return result
    }

    return undefined
  }
}

class DateTransformer extends BaseTransformer {
  transform = (event: FormEvent | Value): string | undefined => {
    const value = super.transform(event)

    if (!value || typeof value !== 'string') {
      return undefined
    }

    try {
      return formatISO(parseISO(value), { representation: 'date' })
    } catch (error) {
      return undefined
    }
  }
}

class SelectTransformer extends BaseTransformer {
  transform = (event: FormEvent | Value): Value => {
    const value = super.transform(event)

    if (!value) {
      return null
    }

    if (typeof value === 'object' && 'value' in value) {
      return value
    }

    if (typeof value === 'string') {
      return { value }
    }

    return null
  }
}

type ComponentExtraProps = (TextFieldProps | DatePickerProps | TextAreaProps | SelectProps<{ value: string }, true>) & { type?: string }

type Field<T> = {
  component: React.ForwardRefExoticComponent<ComponentExtraProps>,
  default: unknown
  validation: BaseValidation<T>
  transformer: BaseTransformer
  name: string
  testId: string
  props: ComponentExtraProps
}

export const fieldFactory = (id: string, type: keyof typeof VariableType, isRequired: boolean = false, config: { options?: string[] } = {}): Field<Value> => {
  const name = fieldNameRealToForm(id)
  const testId = `variable-${id}`

  switch (VariableType[type as keyof typeof VariableType]) {
    case VariableType.text:
      return {
        name,
        testId,
        default: '',
        props: {},
        component: Textfield,
        validation: new ShortTextValidation(isRequired),
        transformer: new ShortTextTransformer(isRequired),
      }
    case VariableType.longtext:
      return {
        name,
        testId,
        default: '',
        props: {},
        component: Textarea,
        validation: new TextValidation(isRequired),
        transformer: new TextTransformer(isRequired),
      }
    case VariableType.number:
      return {
        name,
        testId,
        default: undefined,
        component: Textfield,
        validation: new NumberValidation(isRequired),
        transformer: new NumberTransformer(isRequired),
        props: {
          type: 'number',
          step: 'any'
        }
      }
    case VariableType.date:
      return {
        name,
        testId,
        default: '',
        // @ts-ignore: Types of property 'defaultProps' are incompatible.
        component: DatePicker,
        validation: new DateValidation(isRequired),
        transformer: new DateTransformer(isRequired),
        props: {
          locale: window?.navigator?.languages[0] || 'en-US',
        }
      }
    case VariableType.select:
      return {
        name,
        testId,
        default: '',
        // @ts-ignore
        component: Select,
        validation: new SelectValidation(isRequired, config),
        transformer: new SelectTransformer(isRequired),
        props: {
          getOptionLabel: ({ value }) => value,
          options: (config.options || []).map((value) => ({ value }))
        },
      }
    case VariableType.multiselect:
      return {
        name,
        testId,
        default: '',
        // @ts-ignore
        component: Select,
        validation: new SelectValidation(isRequired, config),
        transformer: new SelectTransformer(isRequired),
        props: {
          isMulti: true,
          getOptionLabel: ({ value }) => value,
          options: (config.options || []).map((value) => ({ value }))
        }
      }
    default:
      return {
        name,
        testId,
        default: '',
        component: Textfield,
        validation: new BaseValidation(isRequired),
        transformer: new BaseTransformer(isRequired),
        props: {}
      }
  }
}

export default fieldFactory
