/* tslint:disable */
/* eslint-disable */
/**
 * Easy Templates Internal API
 * This is an internal REST API for Easy Templates application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Variable
 */
export interface Variable {
    /**
     * Label of the variable, used in markup
     * @type {string}
     * @memberof Variable
     */
    label?: string;
    /**
     * Description of the variable, used to explain its purpose
     * @type {string}
     * @memberof Variable
     */
    description?: string;
    /**
     * Whether the variable is required to have a value to create issues from its template
     * @type {boolean}
     * @memberof Variable
     */
    required?: boolean;
    /**
     * The type of the variable, specifies both the kind of data a variable can store and what widget is used for its display
     * @type {string}
     * @memberof Variable
     */
    type?: VariableTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    defaultValue?: string | null;
}


/**
 * @export
 */
export const VariableTypeEnum = {
    Text: 'text',
    Longtext: 'longtext',
    Number: 'number',
    Date: 'date',
    Select: 'select'
} as const;
export type VariableTypeEnum = typeof VariableTypeEnum[keyof typeof VariableTypeEnum];


/**
 * Check if a given object implements the Variable interface.
 */
export function instanceOfVariable(value: object): value is Variable {
    return true;
}

export function VariableFromJSON(json: any): Variable {
    return VariableFromJSONTyped(json, false);
}

export function VariableFromJSONTyped(json: any, ignoreDiscriminator: boolean): Variable {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'] == null ? undefined : json['label'],
        'description': json['description'] == null ? undefined : json['description'],
        'required': json['required'] == null ? undefined : json['required'],
        'type': json['type'] == null ? undefined : json['type'],
        'defaultValue': json['defaultValue'] == null ? undefined : json['defaultValue'],
    };
}

export function VariableToJSON(value?: Variable | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
        'description': value['description'],
        'required': value['required'],
        'type': value['type'],
        'defaultValue': value['defaultValue'],
    };
}

