import Button, { ButtonGroup, ButtonProps } from "components/ui/Button"
import { type IconType } from "components/ui/icons"

type Item = {
  id: string
  label: string
  onClick: () => void
  isProcessing?: boolean
  isDisabled?: boolean
  appearance?: ButtonProps["appearance"]
  leftIcon?: IconType
  rightIcon?: IconType
}

interface Props {
  items: Item[]
  isProcessing: boolean
  onClose?: () => void
}

const Actions = ({ items, onClose, isProcessing }: Props): JSX.Element | null => {
  return (
    <ButtonGroup>
      {onClose && (
        <Button onClick={onClose} appearance="subtle">
          Close
        </Button>
      )}
      {isProcessing ? <Button isLoading appearance="subtle">Loading...</Button> : items.map(
        ({
          appearance,
          id,
          isDisabled,
          isProcessing,
          label,
          onClick,
          leftIcon: IconBefore,
          rightIcon: IconAfter,
        }) => (
          <Button
            testId={`new-issue-${id}-button`}
            key={label}
            onClick={onClick}
            appearance={appearance || "default"}
            isDisabled={isDisabled}
            isLoading={isProcessing}
            iconBefore={IconBefore}
            iconAfter={IconAfter}
          >
            {label}
          </Button>
        )
      )}
    </ButtonGroup>
  )
}

export default Actions
