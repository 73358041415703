import { useMutation } from "@tanstack/react-query"
import { VariableValues } from "@easy-templates/types"
import { useAppContext } from "components/AppContextProvider"

type Params = {
  issueKey: string
  projectId: string
  templateId: string
  variableValues: VariableValues
}

type Result = { jobId: string } | {
  [key: string]: {
    issueId: string,
    status: string,
    result: string
  }
}

export type Action = (templateId: string, variableValues: {}) => Promise<Result>

export const useApplyTemplate = () => {
  const { core } = useAppContext()

  const { mutateAsync, isPending: isLoading } = useMutation<Result, Error, Params>({
    mutationFn: async ({ templateId, variableValues, issueKey, projectId }) => {
      const result = await core.applyTemplate({ id: templateId, variableValues, issueKey, projectId })

      return result
    },
  })

  return {
    applyTemplate: mutateAsync,
    isProcessing: isLoading,
  }
}

export default useApplyTemplate
